<template>
  <div>
    <el-table
      :height="tableHeight"
      :data="displayDatas"
      tooltip-effect="dark"
      stripe
      style="width: 100%"
    >
      <el-table-column type="index" width="50" />
      <el-table-column prop="title" label="标题" />
      <el-table-column prop="discount" label="金额(¥)" />
      <el-table-column prop="surplus_cnt" label="剩余库存" />
      <el-table-column label="赠券数(人均)">
        <template v-slot="{ row, $index }">
          <el-input
            :value="row.cnt"
            @input="onInput($event, $index)"
            min="1"
            :max="row.surplus_cnt"
            placeholder="赠券数"
            style="max-width:7em;"
          />
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="onDelProduct(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="xa-cell xa-txt-placeholder">
      <div class="xa-flex">已选：优惠券（{{ datas.length }}）</div>
      <el-pagination
        layout="prev, pager, next"
        :page-count="totoPid"
        :current-page="pid + 1"
        @current-change="onChangePage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Base from './DisplayProductTable'
export default {
  extends: Base,
  methods: {
    onInput(cnt, index) {
      window.console.log('onInput', ...arguments)
      index = this.pid * this.pSize + index
      let data = this.datas[index]
      window.console.log('onInput', data)
      const max = data.surplus_cnt
      data.cnt = isFinite(max) ? Math.min(cnt, max) : cnt
      this.datas.splice(index, 1, data)
    }
  }
}
</script>
